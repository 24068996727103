@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.progressBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background:red;
  transform-origin: 0%;
}

.App {
  font-family: "DM Sans", sans-serif;
  text-align: center;
}

html {
  font-family: "DM Sans", sans-serif;
}

.circle {
  position: fixed;
  z-index: 100;
  display: flex;
  flex-flow: row;
  align-content: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 10px;
  width: 10px;
  background-color: #1e91d6;
  border-radius: 200px;
  pointer-events: none;
  color: #fff;
  text-align: center;
  font-size: 16px;
}

.cursorText {
  flex: auto;
  font-size: inherit;
  pointer-events: none;
  margin: auto;
}

.container {
  display: flex;
  flex-flow: row;
  height: 90vh;
  width: 98vw;
  background: #fff;
  border-radius: 20px;
  padding: 40px;
}

.project {
  display: inline-block;
  position: relative;
  z-index: 1;
  height: 652px;
  width: 601px;
  background: #1e91d6;
  border-radius: 24px;
  cursor: none;
  background-image: url("https://cdn.discordapp.com/attachments/577203676108685331/790326977714192465/Tile_Image.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.projectName {
  background: #fff;
  border-radius: 34px;
  position: absolute;
  bottom: 30px;
  left: 30px;
  padding: 12px 20px;
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
}

.contactWrapper {
  padding: 120px;
}

.contact {
  font-weight: 500;
  font-size: 36px;
  line-height: 1.2;
  text-decoration: underline;
  transition: 150ms ease;
}

.contact:hover {
  color: #1e91d6;
  cursor: pointer;
}
