 .banner-shapes .banner-diamond-shape {
    bottom: 11%;
    right: 15%;
    -webkit-animation: float-bob-y 2s linear infinite;
    animation: float-bob-y 2s linear infinite;
}
 .banner-shapes .banner-curly-shape {
    top: 30%;
    right: 0;
    -webkit-animation-name: float-bob-x;
    animation-name: float-bob-x;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

 .banner-shapes .banner-square-dots-1 {
    top: 0;
    left: -2%;
    -webkit-animation: float-bob-x 4s linear infinite;
    animation: float-bob-x 4s linear infinite;
}

 .banner-shapes .banner-square-dots-2 {
    top: 60%;
    left: 53%;
    z-index: 1;
    -webkit-animation: float-bob-y 4s linear infinite;
    animation: float-bob-y 4s linear infinite;
}
 .banner-shapes>img {
    position: absolute;
}

.slick-dots li button:before{
    color:#fff !important;
}
.btn.btn-primary {
    background-color: #4d41e1;
    border-color: #4d41e1;
 }

.btn.btn-large {
    font-size: 18px;
    line-height: 1;
    padding: 20px 25px;
    min-width: 180px;
    box-shadow: none!important;
}
.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
    transition: #0d6efd .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn-primary {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
}
.btn{
    -webkit-transform: translateY(0) translateX(0) scale(1);
    transform: translateY(0) translateX(0) scale(1);
    opacity: 1;
}

.btn-primary:hover{
    background-color: #fff;
    color: #0d6efd;
    transition: #0d6efd .45s ease-in-out,#fff.45s ease-in-out,;


}


.btn:after, .btn:before {
    content: "";
    background-color: rgba(0,0,0,.07);
    height: 50%;
    width: 0;
    position: absolute;
    transition: .3s cubic-bezier(.785,.135,.15,.86);
    -webkit-transition: .3s cubic-bezier(.785,.135,.15,.86);
    z-index: -1;
}

 .fi-icon:before {
    content: "";
    display: block;
    position: absolute !important;
    width: 45px;
    height: 45px;
    opacity: .1;
    border-radius: 50%;
    top: -2%;
    left: -20%;
    /* padding-right: 40px !important; */
    z-index: 10;
    background-color: #4d41e1;

}
.box{
    display: block;
    position: relative;
}

.box1{
    display: flex;
    position: relative;
}
.fi-icon1:before {
    content: "";
    display: block;
    position: absolute !important;
    width: 45px;
    height: 45px;
    opacity: .1;
    border-radius: 50%;
    top: -2%;
    left: -20%;
    /* padding-right: 40px !important; */
    z-index: 10;
    background-color: #4d41e1;

}
.slick-slide {
    margin: 0 10px;
  }
  .slick-list {
    margin: 0 -10px;
  }
 .support-mail {
    width: 82%;
    top: 50%;
    right: 72px;
    border-radius: 5px;
    text-align: center;
    padding: 20px;
    -webkit-transform: rotate(-90deg) translateX(50%);
    transform: rotate(-90deg) translateX(50%);
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    transition: .3s;
    margin: 0;
    color: #fff;
    font-size: 20px;
    background-color: #4d41e1
}

.features-color-dots {
    width: 120px;
    height: 160px;
    top: 14px;
    right: -35px;
    z-index: -1;
    position: absolute;
    background-image: url('../../assets/dot1.svg');
}

.features-white-dots {
    width: 80px;
    height: 102px;
    bottom: 13px;
    right: 17px;
    position: absolute;
    background-image: url('../../assets/dot2.svg');
}

.testinomial{
    display: flex !important;
}

@mediaonly screen and (max-width: 600px) {
    .slick-slide {
        margin: 0 3px;
      }
      .slick-list {
        margin: 0 -3px;
      }
}
@keyframes float-bob-y{
    0% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    
    50% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
    100% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
}

@keyframes float-bob-x {
    0% {
        -webkit-transform: translateX(-30px);
        transform: translateX(-30px);
    }
    
    50% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px);
    }
    100% {
        -webkit-transform: translateX(-30px);
        transform: translateX(-30px);
    }
}

