.banner-shapes .banner-diamond-shape {
    bottom: 11%;
    right: 15%;
    -webkit-animation: float-bob-y 2s linear infinite;
    animation: float-bob-y 2s linear infinite;
}
 .banner-shapes .banner-curly-shape {
    top: 30%;
    right: 0;
    -webkit-animation-name: float-bob-x;
    animation-name: float-bob-x;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

 .banner-shapes .banner-square-dots-1 {
    top: 0;
    left: -2%;
    -webkit-animation: float-bob-x 4s linear infinite;
    animation: float-bob-x 4s linear infinite;
}

 .banner-shapes .banner-square-dots-2 {
    top: 60%;
    left: 53%;
    z-index: 1;
    -webkit-animation: float-bob-y 4s linear infinite;
    animation: float-bob-y 4s linear infinite;
}
 .banner-shapes>img {
    position: absolute;
}
.footerBg{
    background-color: #4d41e1 !important;
}
@keyframes float-bob-y{
    0% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    
    50% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
    100% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
}

@keyframes float-bob-x {
    0% {
        -webkit-transform: translateX(-30px);
        transform: translateX(-30px);
    }
    
    50% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px);
    }
    100% {
        -webkit-transform: translateX(-30px);
        transform: translateX(-30px);
    }
}