:root {
    --dl-color-gray-500: #595959;
    --dl-color-gray-700: #999999;
    --dl-color-gray-900: #D9D9D9;
    --dl-size-size-large: 144px;
    --dl-size-size-small: 48px;
    --dl-color-danger-300: #A22020;
    --dl-color-danger-500: #BF2626;
    --dl-color-danger-700: #E14747;
    --dl-color-gray-black: #000000;
    --dl-color-gray-white: #FFFFFF;
    --dl-size-size-medium: 96px;
    --dl-size-size-xlarge: 192px;
    --dl-size-size-xsmall: 16px;
    --dl-space-space-unit: 16px;
    --dl-color-primary-100: #003EB3;
    --dl-color-primary-300: #0074F0;
    --dl-color-primary-500: #14A9FF;
    --dl-color-primary-700: #85DCFF;
    --dl-color-success-300: #199033;
    --dl-color-success-500: #32A94C;
    --dl-color-success-700: #4CC366;
    --dl-size-size-xxlarge: 288px;
    --dl-size-size-maxwidth: 1400px;
    --dl-radius-radius-round: 50%;
    --dl-space-space-halfunit: 8px;
    --dl-space-space-sixunits: 96px;
    --dl-space-space-twounits: 32px;
    --dl-radius-radius-radius2: 2px;
    --dl-radius-radius-radius4: 4px;
    --dl-radius-radius-radius8: 8px;
    --dl-space-space-fiveunits: 80px;
    --dl-space-space-fourunits: 64px;
    --dl-space-space-threeunits: 48px;
    --dl-space-space-oneandhalfunits: 24px;
  }
  .button {
    color: var(--dl-color-gray-black);
    display: inline-block;
    padding: 0.5rem 1rem;
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    border-radius: 4px;
    background-color: var(--dl-color-gray-white);
  }
  .input {
    color: var(--dl-color-gray-black);
    cursor: auto;
    padding: 0.5rem 1rem;
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    border-radius: 4px;
    background-color: var(--dl-color-gray-white);
  }
  .textarea {
    color: var(--dl-color-gray-black);
    cursor: auto;
    padding: 0.5rem;
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    border-radius: 4px;
    background-color: var(--dl-color-gray-white);
  }
  .list {
    width: 100%;
    margin: 1em 0px 1em 0px;
    display: block;
    padding: 0px 0px 0px 1.5rem;
    list-style-type: none;
    list-style-position: outside;
  }
  .list-item {
    display: list-item;
  }
  .teleport-show {
    display: flex !important;
    transform: none !important;
  }
  .Content {
    font-size: 16px;
    font-family: Inter;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .Heading {
    font-size: 32px;
    font-family: Inter;
    font-weight: 700;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  
.home-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: center;
 
    
    background-color: white;
  }
  .home-hero {
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-threeunits);
    max-width: var(--dl-size-size-maxwidth);
    min-height: 80vh;
    align-items: center;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .home-container1 {
    display: flex;
    margin-right: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-text {
    font-size: 3rem;
    max-width: 550px;
    color: #000;
  }
  .home-text10 {
    font-size: 3rem;
    max-width: 550px;
    color: white;
  }
  .home-text1 {
    margin-top: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-btn-group {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .home-button {
    transition: 0.3s;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-button:hover {
    color: var(--dl-color-gray-white);
    background-color: var(--dl-color-gray-black);
  }
  .home-button1 {
    transition: 0.3s;
    margin-left: var(--dl-space-space-unit);
    padding-top: var(--dl-space-space-unit);
    border-color: transparent;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-button1:hover {
    border-color: var(--dl-color-gray-black);
  }
  .home-image {
    width: 400px;
    object-fit: cover;
  }
  @media(max-width: 991px) {
    .home-hero {
      flex-direction: column;
    }
    .home-container1 {
      align-items: center;
      margin-right: 0px;
      margin-bottom: var(--dl-space-space-twounits);
      padding-right: 0px;
    }
    .home-text {
      text-align: center;
    }
    .home-text1 {
      text-align: center;
      padding-left: var(--dl-space-space-threeunits);
      padding-right: var(--dl-space-space-threeunits);
    }
  }
  @media(max-width: 767px) {
    .home-hero {
      padding-left: var(--dl-space-space-twounits);
      padding-right: var(--dl-space-space-twounits);
    }
    .home-text1 {
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
    }
    .home-image {
      width: 80%;
    }
  }
  @media(max-width: 479px) {
    .home-hero {
      padding-top: var(--dl-space-space-twounits);
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
      padding-bottom: var(--dl-space-space-twounits);
    }
    .home-container1 {
      margin-bottom: var(--dl-space-space-unit);
    }
    .home-text {
      padding-top: var(--dl-space-space-fourunits);
    }
    .home-btn-group {
      flex-direction: column;
    }
    .home-button1 {
      margin-top: var(--dl-space-space-unit);
      margin-left: 0px;
    }
  }
  
  .home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-steps {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.home-text {
  color: var(--dl-color-gray-black);
  font-size: 3rem;
}
.home-text02 {
  color: var(--dl-color-gray-700);
  font-size: 1.15rem;
  max-width: 600px;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.home-container01 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-step {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-container02 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 2px;
  padding-right: 2px;
  flex-direction: row;
  justify-content: center;
}
.home-line {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: transparent;
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-container03 {
  flex: 0 0 auto;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5ff;
}
.home-icon {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
}
.home-line1 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-container04 {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.home-text05 {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text07 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  text-align: center;
}
.home-step1 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-container05 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 2px;
  padding-right: 2px;
  flex-direction: row;
  justify-content: center;
}
.home-line2 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-container06 {
  flex: 0 0 auto;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5ff;
}
.home-icon2 {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
}
.home-line3 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-container07 {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.home-text10 {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text12 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  text-align: center;
}
.home-step2 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-container08 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 2px;
  padding-right: 2px;
  flex-direction: row;
  justify-content: center;
}
.home-line4 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-container09 {
  flex: 0 0 auto;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5ff;
}
.home-icon4 {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
}
.home-line5 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-container10 {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.home-text15 {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text17 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  text-align: center;
}
.home-step3 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-container11 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 2px;
  padding-right: 2px;
  flex-direction: row;
  justify-content: center;
}
.home-line6 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-container12 {
  flex: 0 0 auto;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5ff;
}
.home-icon7 {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
}
.home-line7 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: transparent;
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-container13 {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.home-text20 {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text22 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  text-align: center;
}
@media(max-width: 991px) {
  .home-text {
    text-align: center;
  }
  .home-text02 {
    text-align: center;
  }
}
@media(max-width: 767px) {
  .home-steps {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-container01 {
    align-items: flex-start;
    padding-left: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .home-step {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }
  .home-container02 {
    width: var(--dl-size-size-small);
    height: auto;
    align-self: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    padding-bottom: 2px;
    justify-content: space-between;
  }
  .home-line1 {
    border-color: var(--dl-color-gray-900);
    border-style: dashed;
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .home-container04 {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .home-text05 {
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text07 {
    text-align: left;
  }
  .home-step1 {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }
  .home-container05 {
    width: var(--dl-size-size-small);
    height: auto;
    align-self: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    padding-bottom: 2px;
    justify-content: space-between;
  }
  .home-line2 {
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .home-line3 {
    border-color: var(--dl-color-gray-900);
    border-style: dashed;
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .home-container07 {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .home-text10 {
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text12 {
    text-align: left;
  }
  .home-step2 {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }
  .home-container08 {
    width: var(--dl-size-size-small);
    height: auto;
    align-self: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    padding-bottom: 2px;
    justify-content: space-between;
  }
  .home-line4 {
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .home-line5 {
    border-color: var(--dl-color-gray-900);
    border-style: dashed;
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .home-container10 {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .home-text15 {
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text17 {
    text-align: left;
  }
  .home-step3 {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }
  .home-container11 {
    width: var(--dl-size-size-small);
    height: auto;
    align-self: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    padding-bottom: 2px;
    justify-content: space-between;
  }
  .home-line6 {
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .home-line7 {
    border-color: transparent;
    border-style: dashed;
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .home-container13 {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .home-text20 {
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text22 {
    text-align: left;
  }
}
@media(max-width: 479px) {
  .home-steps {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container01 {
    padding-left: 0px;
  }
  .home-container04 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-text05 {
    margin-left: 0px;
    margin-right: var(--dl-space-space-twounits);
  }
  .home-container07 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-text10 {
    margin-left: 0px;
    margin-right: var(--dl-space-space-twounits);
  }
  .home-container10 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-text15 {
    margin-left: 0px;
    margin-right: var(--dl-space-space-twounits);
  }
  .home-container13 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-text20 {
    margin-left: 0px;
    margin-right: var(--dl-space-space-twounits);
  }
}

  .home-steps {
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-threeunits);
    max-width: var(--dl-size-size-maxwidth);
    align-items: center;
    flex-direction: column;
  }
  .home-text {
    color: var(--dl-color-gray-black);
    font-size: 3rem;
  }
  .home-text02 {
    color: var(--dl-color-gray-700);
    font-size: 1.15rem;
    max-width: 600px;
    margin-top: var(--dl-space-space-unit);
    text-align: center;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-container01 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }
  .home-step {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .home-container02 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-left: 2px;
    padding-right: 2px;
    flex-direction: row;
    justify-content: center;
  }
  .home-line {
    flex: 1;
    height: 0px;
    display: flex;
    align-items: flex-start;
    border-color: transparent;
    border-style: dashed;
    border-width: 2px;
    flex-direction: row;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .home-container03 {
    flex: 0 0 auto;
    width: var(--dl-size-size-small);
    height: var(--dl-size-size-small);
    display: flex;
    align-items: center;
    border-radius: var(--dl-radius-radius-round);
    flex-direction: row;
    justify-content: center;
    background-color: #f5f5f5ff;
  }
  .home-icon {
    fill: var(--dl-color-gray-500);
    width: 24px;
    height: 24px;
  }
  .home-line1 {
    flex: 1;
    height: 0px;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-gray-900);
    border-style: dashed;
    border-width: 2px;
    flex-direction: row;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .home-container04 {
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-text05 {
    font-size: 1.5rem;
    text-align: center;
    font-weight: 500;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text07 {
    color: var(--dl-color-gray-500);
    font-size: 0.75rem;
    text-align: center;
  }
  .home-step1 {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .home-container05 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-left: 2px;
    padding-right: 2px;
    flex-direction: row;
    justify-content: center;
  }
  .home-line2 {
    flex: 1;
    height: 0px;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-gray-900);
    border-style: dashed;
    border-width: 2px;
    flex-direction: row;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .home-container06 {
    flex: 0 0 auto;
    width: var(--dl-size-size-small);
    height: var(--dl-size-size-small);
    display: flex;
    align-items: center;
    border-radius: var(--dl-radius-radius-round);
    flex-direction: row;
    justify-content: center;
    background-color: #f5f5f5ff;
  }
  .home-icon2 {
    fill: var(--dl-color-gray-500);
    width: 24px;
    height: 24px;
  }
  .home-line3 {
    flex: 1;
    height: 0px;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-gray-900);
    border-style: dashed;
    border-width: 2px;
    flex-direction: row;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .home-container07 {
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-text10 {
    font-size: 1.5rem;
    text-align: center;
    font-weight: 500;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text12 {
    color: var(--dl-color-gray-500);
    font-size: 0.75rem;
    text-align: center;
  }
  .home-step2 {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .home-container08 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-left: 2px;
    padding-right: 2px;
    flex-direction: row;
    justify-content: center;
  }
  .home-line4 {
    flex: 1;
    height: 0px;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-gray-900);
    border-style: dashed;
    border-width: 2px;
    flex-direction: row;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .home-container09 {
    flex: 0 0 auto;
    width: var(--dl-size-size-small);
    height: var(--dl-size-size-small);
    display: flex;
    align-items: center;
    border-radius: var(--dl-radius-radius-round);
    flex-direction: row;
    justify-content: center;
    background-color: #f5f5f5ff;
  }
  .home-icon4 {
    fill: var(--dl-color-gray-500);
    width: 24px;
    height: 24px;
  }
  .home-line5 {
    flex: 1;
    height: 0px;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-gray-900);
    border-style: dashed;
    border-width: 2px;
    flex-direction: row;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .home-container10 {
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-text15 {
    font-size: 1.5rem;
    text-align: center;
    font-weight: 500;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text17 {
    color: var(--dl-color-gray-500);
    font-size: 0.75rem;
    text-align: center;
  }
  .home-step3 {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .home-container11 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-left: 2px;
    padding-right: 2px;
    flex-direction: row;
    justify-content: center;
  }
  .home-line6 {
    flex: 1;
    height: 0px;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-gray-900);
    border-style: dashed;
    border-width: 2px;
    flex-direction: row;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .home-container12 {
    flex: 0 0 auto;
    width: var(--dl-size-size-small);
    height: var(--dl-size-size-small);
    display: flex;
    align-items: center;
    border-radius: var(--dl-radius-radius-round);
    flex-direction: row;
    justify-content: center;
    background-color: #f5f5f5ff;
  }
  .home-icon7 {
    fill: var(--dl-color-gray-500);
    width: 24px;
    height: 24px;
  }
  .home-line7 {
    flex: 1;
    height: 0px;
    display: flex;
    align-items: flex-start;
    border-color: transparent;
    border-style: dashed;
    border-width: 2px;
    flex-direction: row;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .home-container13 {
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-text20 {
    font-size: 1.5rem;
    text-align: center;
    font-weight: 500;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text22 {
    color: var(--dl-color-gray-500);
    font-size: 0.75rem;
    text-align: center;
  }
  @media(max-width: 991px) {
    .home-text {
      text-align: center;
    }
    .home-text02 {
      text-align: center;
    }
  }
  @media(max-width: 767px) {
    .home-steps {
      padding-left: var(--dl-space-space-twounits);
      padding-right: var(--dl-space-space-twounits);
    }
    .home-container01 {
      align-items: flex-start;
      padding-left: var(--dl-space-space-fiveunits);
      flex-direction: column;
    }
    .home-step {
      width: 100%;
      height: auto;
      flex-direction: row;
      justify-content: center;
    }
    .home-container02 {
      width: var(--dl-size-size-small);
      height: auto;
      align-self: stretch;
      padding-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
      flex-direction: column;
      padding-bottom: 2px;
      justify-content: space-between;
    }
    .home-line1 {
      border-color: var(--dl-color-gray-900);
      border-style: dashed;
      border-top-width: 0px;
      border-left-width: 2px;
    }
    .home-container04 {
      width: 100%;
      align-items: flex-start;
      padding-top: var(--dl-space-space-twounits);
      flex-direction: column;
      padding-bottom: var(--dl-space-space-twounits);
      justify-content: space-between;
    }
    .home-text05 {
      margin-top: var(--dl-space-space-twounits);
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: var(--dl-space-space-unit);
    }
    .home-text07 {
      text-align: left;
    }
    .home-step1 {
      width: 100%;
      height: auto;
      flex-direction: row;
      justify-content: center;
    }
    .home-container05 {
      width: var(--dl-size-size-small);
      height: auto;
      align-self: stretch;
      padding-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
      flex-direction: column;
      padding-bottom: 2px;
      justify-content: space-between;
    }
    .home-line2 {
      border-top-width: 0px;
      border-left-width: 2px;
    }
    .home-line3 {
      border-color: var(--dl-color-gray-900);
      border-style: dashed;
      border-top-width: 0px;
      border-left-width: 2px;
    }
    .home-container07 {
      width: 100%;
      align-items: flex-start;
      padding-top: var(--dl-space-space-twounits);
      flex-direction: column;
      padding-bottom: var(--dl-space-space-twounits);
      justify-content: space-between;
    }
    .home-text10 {
      margin-top: var(--dl-space-space-twounits);
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: var(--dl-space-space-unit);
    }
    .home-text12 {
      text-align: left;
    }
    .home-step2 {
      width: 100%;
      height: auto;
      flex-direction: row;
      justify-content: center;
    }
    .home-container08 {
      width: var(--dl-size-size-small);
      height: auto;
      align-self: stretch;
      padding-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
      flex-direction: column;
      padding-bottom: 2px;
      justify-content: space-between;
    }
    .home-line4 {
      border-top-width: 0px;
      border-left-width: 2px;
    }
    .home-line5 {
      border-color: var(--dl-color-gray-900);
      border-style: dashed;
      border-top-width: 0px;
      border-left-width: 2px;
    }
    .home-container10 {
      width: 100%;
      align-items: flex-start;
      padding-top: var(--dl-space-space-twounits);
      flex-direction: column;
      padding-bottom: var(--dl-space-space-twounits);
      justify-content: space-between;
    }
    .home-text15 {
      margin-top: var(--dl-space-space-twounits);
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: var(--dl-space-space-unit);
    }
    .home-text17 {
      text-align: left;
    }
    .home-step3 {
      width: 100%;
      height: auto;
      flex-direction: row;
      justify-content: center;
    }
    .home-container11 {
      width: var(--dl-size-size-small);
      height: auto;
      align-self: stretch;
      padding-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
      flex-direction: column;
      padding-bottom: 2px;
      justify-content: space-between;
    }
    .home-line6 {
      border-top-width: 0px;
      border-left-width: 2px;
    }
    .home-line7 {
      border-color: transparent;
      border-style: dashed;
      border-top-width: 0px;
      border-left-width: 2px;
    }
    .home-container13 {
      width: 100%;
      align-items: flex-start;
      padding-top: var(--dl-space-space-twounits);
      flex-direction: column;
      padding-bottom: var(--dl-space-space-twounits);
      justify-content: space-between;
    }
    .home-text20 {
      margin-top: var(--dl-space-space-twounits);
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: var(--dl-space-space-unit);
    }
    .home-text22 {
      text-align: left;
    }
  }
  @media(max-width: 479px) {
    .home-steps {
      padding-top: var(--dl-space-space-twounits);
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
      padding-bottom: var(--dl-space-space-twounits);
    }
    .home-container01 {
      padding-left: 0px;
    }
    .home-container04 {
      padding-top: var(--dl-space-space-twounits);
      padding-left: var(--dl-space-space-unit);
      padding-right: 0px;
      padding-bottom: var(--dl-space-space-twounits);
    }
    .home-text05 {
      margin-left: 0px;
      margin-right: var(--dl-space-space-twounits);
    }
    .home-container07 {
      padding-top: var(--dl-space-space-twounits);
      padding-left: var(--dl-space-space-unit);
      padding-right: 0px;
      padding-bottom: var(--dl-space-space-twounits);
    }
    .home-text10 {
      margin-left: 0px;
      margin-right: var(--dl-space-space-twounits);
    }
    .home-container10 {
      padding-top: var(--dl-space-space-twounits);
      padding-left: var(--dl-space-space-unit);
      padding-right: 0px;
      padding-bottom: var(--dl-space-space-twounits);
    }
    .home-text15 {
      margin-left: 0px;
      margin-right: var(--dl-space-space-twounits);
    }
    .home-container13 {
      padding-top: var(--dl-space-space-twounits);
      padding-left: var(--dl-space-space-unit);
      padding-right: 0px;
      padding-bottom: var(--dl-space-space-twounits);
    }
    .home-text20 {
      margin-left: 0px;
      margin-right: var(--dl-space-space-twounits);
    }
  }
  